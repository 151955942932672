import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const DoigtARessaut = () => (
  <Layout
    title="Doigt à ressaut : Diagnostic et traitement"
    description="Le doigt à ressaut est la conséquence d'un conflit entre le tendon fléchisseur et la poulie. Ce conflit siège préférentiellement au niveau de la paume de la main."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Doigt à ressaut" />
        <MainPart title="Qu'est-ce qu'un doigt à ressaut ?">
          <Typography weight="bold">
            Le doigt à ressaut
            {' '}
            <Typography variant="span">
              {' '}
              est la conséquence d&apos;un conflit entre le tendon fléchisseur et la poulie.
              Ce conflit siège préférentiellement au niveau de la paume de la main.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment sont constitués les doigts ?">
          <Typography>
            L&apos;appareil fléchisseur de chaque doigt long est constitué de deux tendons : le
            fléchisseur superficiel et le fléchisseur profond. Ces tendons coulissent
            dans une gaine fermée qui est renforcée à certains endroits par des poulies.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les causes possibles d'un doigt à ressaut ?">
          <Typography>
            L&apos;inflammation de la gaine synoviale qui entoure les
            tendons fléchisseurs, va aboutir
            à la création d&apos;un véritable nodule à l&apos;intérieur du tendon. Ce nodule est
            palpable à la paume, rentre en conflit avec la poulie provoquant ainsi le ressaut
            (Figure 1).
          </Typography>
          <Figure
            imageName="figure1-doigtaressaut.jpg"
            caption="Figure 1. Épaississement du tendon fléchisseur avec création de nodule, responsable d&apos;un conflit avec la poulie A1"
            currentOpTitle="Doigt à ressaut"
          />
          <div className="flex flex-col">
            <Typography>
              Cette inflammation peut être en rapport avec une pathologie
              rhumatismale ou des gestes mécaniques répétitifs.
            </Typography>
            <Typography>
              Les autres causes rares du doigt à ressaut sont :
            </Typography>
          </div>
          <List>
            <List.Item>
              <Typography>
                dans les suites d&apos;une plaie partielle des tendons fléchisseurs
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                dans les suites d&apos;une chirurgie du canal
                carpien (sans toutefois en constituer une complication)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                forme congénitale (surtout le pouce)
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Quels sont les symptômes d'un doigt à ressaut ?">
          <Typography>
            Le diagnostic est clinique. Elle se traduit par des blocage incomplets
            ou complets, surtout au réveil, des doigts lors des mouvements de
            flexion et d&apos;extension (Figure 2).
          </Typography>
          <Figure
            imageName="figure2-doigtaressaut.jpg"
            caption="Figure 2. Doigt à ressaut de l&apos;annulaire bloqué en flexion du doigt"
            currentOpTitle="Doigt à ressaut"
          />
          <div className="flex flex-col">
            <Typography>
              Lorsque le phénomène est ancien, on peut observer une raideur articulaire (flessum)
              et/ou une atteinte des tendons fléchisseurs (effilochage, dilacération voire
              rupture tendineuse) nécessitant un traitement adéquat.
            </Typography>
            <Typography>
              La forme congénitale apparaît dans la première année de vie et se traduit
              le plus souvent par un pouce bloqué en permanence en flexion ou en extension.
            </Typography>
            <Typography>
              Le doigt à ressaut est souvent associé dans le temps, à un syndrome
              du canal carpien (synovite des tendons fléchisseurs).
            </Typography>
          </div>
        </MainPart>
        <MainPart title="Quels sont les examens qui confirment un doigt à ressaut ?">
          <Typography>
            La réalisation d&apos;une radiographie et d&apos;une échographie
            de la main permet de conforter le diagnostique.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux d'un doigt à ressaut ?">
          <Typography size="mdx" weight="bold">Traitement médical :</Typography>
          <Typography weight="bold">
            Une infiltration de corticoïdes peut être envisagée
            {' '}
            <Typography variant="span">
              dans un premier temps. Elle est réalisée au niveau de la paume de la main.
              Son efficacité est rapide (jugée au bout d&apos;un mois)
              mais le plus souvent temporaire.
              Il est important de savoir que le doigt ou la main peuvent être douloureux
              2-3 jours après l&apos;infiltration.
            </Typography>
          </Typography>
          <Typography weight="bold">
            Il ne faut pas multiplier les infiltrations
            {' '}
            <Typography variant="span">
              (plus que deux fois) au risque de fragiliser
              voire rompre le tendon.
            </Typography>
          </Typography>
          <Typography size="mdx" weight="bold">Traitement chirurgical :</Typography>
          <Typography>
            L&apos;opération est indiquée en cas d&apos;échec du traitement médical
            ou d&apos;emblée en cas de blocage permanent. Elle est faite en
            ambulatoire (pas d&apos;hospitalisation)
            et sous anesthésie locorégionale (le bras est uniquement endormi).
          </Typography>
          <Typography>
            Il s&apos;agit d&apos;un geste rapide, réalisé par une petite incision au niveau de
            la paume de la main (Figure 3).  La poulie A1 est complètement ouverte
            et la membrane synoviale hypertrophique péri-tendineuse est réséquée.
          </Typography>
          <Figure
            imageName="figure3-doigtaressaut.jpg"
            caption="Figure 3. Incision cutanée pour le traitement chirurgical de doigt à ressaut"
            currentOpTitle="Doigt à ressaut"
          />
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires d'une opération chirurgicale pour un doigt à ressaut ?">
          <Typography>
            Les phénomènes de ressaut et de blocage disparaissent de manière complète
            et définitive dès la levée de l&apos;anesthésie. La cicatrisation de la peau
            est obtenue au bout de 14 jours. Durant cette période, un petit pansement
            est mis en place afin de permettre l&apos;usage de la main pour les gestes
            de la vie quotidienne. La mobilisation du doigt en flexion et en extension
            complètes doit se faire le plus rapidement possible afin d&apos;éviter
            la raideur des doigts. La difficulté à étendre le doigt est possible et
            peut durer plusieurs semaines après l&apos;opération.
          </Typography>
          <Typography>
            La rééducation voire un appareillage du doigt
            peuvent être indiqués dans les formes anciennes.
          </Typography>
          <Typography>
            La reprise du sport et du travail de force est envisageable au bout d&apos;un mois.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour un doigt à ressaut ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Enraidissement du doigt : Il s&apos;agit de la principale complication.
                Elle est liée à une mauvaise mobilisation du doigt par le patient.
                La rééducation et/ou l&apos;appareillage sont alors nécessaires.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Libération incomplète de la poulie : Nécessite une reprise au bloc
                opératoire pour un complément de libération de la poulie A1.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Lésion des nerfs du doigt : Responsable de fourmillements voire
                d&apos;une baisse de la sensibilité du doigt. Elle nécessite une
                suture nerveuse en urgence.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Douleurs persistantes : Souvent tenaces, surtout en cas de
                synovite importante des tendons fléchisseurs.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Œdème de la base du doigt : Le plus souvent transitoire.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Récidive : Très rare. Elle est traitée par infiltration voire ré-intervention.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default DoigtARessaut;
